<template>
    <div class="card">
        <h5>Estação Meteorológica</h5>
        
        <div class="card">
            <div class="formgroup-inline">
                <div class="field">
                    <div class="col-12 md:col-10">
                        <Dropdown id="estacoesMeteorologicas" 
                                v-model="estacao" 
                                :options="estacoes" 
                                optionLabel="name" 
                                placeholder="Estações Meteorológicas"
                                style="min-width: 17rem;" />
                    </div>
                </div>
                <div class="field">
                    <div class="col-12 md:col-10">
                        <Dropdown id="anos" 
                                v-model="ano" 
                                :options="anos" 
                                placeholder="Ano"
                                style="min-width: 8rem;" />
                    </div>
                </div>
                <div class="field">
                    <div class="col-12 md:col-10">
                        <Dropdown id="mes" 
                                v-model="mes" 
                                :options="meses" 
                                optionLabel="name" 
                                style="min-width: 11rem;" />
                    </div>
                </div>
                <div class="field">
                    <div class="col-12 md:col-10">
                        <Button label="Consultar" @click="buscarHistoricoChuva()"></Button>  
                    </div>
                </div>
                <div class="field">
                    <div class="col-12 md:col-10">
                        <Button type="button" 
                            icon="pi pi-filter-slash" 
                            label="Limpar" 
                            class="p-button-outlined" 
                            @click="limparFiltros()" />
                    </div>
                </div>
            </div>
        </div>

        <div class="card" v-if="historicoChuvas.length > 0">
            <Chart type="line" :data="lineData" :options="lineOptions" class="mb-5" />
            <div class="flex sm:flex-column md:flex-row">
                <div class="bg-gray-100 p-4 border-round flex-1">
                    <h5>Milímetros de Chuva</h5>
                    <div class="grid">
                        <div class="flex-1 inline-block bg-blue-300 text-white font-bold text-center p-4 border-round">
                            <p>Mínima</p>
                            <p>{{ formatarDecimal(precipitacaoMinima) }}</p>
                        </div>
                        <div class="flex-1 inline-block bg-blue-500 text-white font-bold text-center p-4 border-round ml-2">
                            <p>Média</p>
                            <p>{{ formatarDecimal(precipitacaoMedia) }}</p>
                        </div>
                        <div class="flex-1 inline-block bg-blue-800 text-white font-bold text-center p-4 border-round ml-2">
                            <p>Máxima</p>
                            <p>{{ formatarDecimal(precipitacaoMaxima) }}</p>
                        </div>
                    </div>
                </div>
                <div class="bg-gray-100 p-4 border-round sm:mt-4 md:mt-0 md:ml-4 flex-1">
                    <h5>Temperatura</h5>
                    <div class="grid">
                        <div class="flex-1 inline-block bg-orange-300 text-white font-bold text-center p-4 border-round">
                            <p>Mínima</p>
                            <p>{{ formatarDecimal(temperaturaMininma) }}</p>
                        </div>
                        <div class="flex-1 inline-block bg-orange-500 text-white font-bold text-center p-4 border-round ml-2">
                            <p>Média</p>
                            <p>{{ formatarDecimal(temperaturaMedia) }}</p>
                        </div>
                        <div class="flex-1 inline-block text-white font-bold text-center p-4 border-round ml-2" style="background-color: red;">
                            <p>Máxima</p>
                            <p>{{ formatarDecimal(temperaturaMaxima) }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>     
</template>

<script>
    import HistoricoChuvaService from '../../service/HistoricoChuvaService';
    import EstacaoMeteorologicaService from '../../service/EstacaoMeteorologicaService';
    import Formatacao from '../../utilities/Formatacao';

    export default {
        name: 'HistoricoChuva',
        data() {
            return {
                estacao: '',
                estacoes: [],
                ano: '',
                anos: [],
                mes: null,
                meses: [
                    {name: 'Todos meses', code: null, abrev: 'Todos meses'},
                    {name: 'janeiro', code: 1, abrev: 'JAN'},
                    {name: 'fevereiro', code: 2, abrev: 'FEV'},
                    {name: 'março', code: 3, abrev: 'MAR'},
                    {name: 'abril', code: 4, abrev: 'ABR'},
                    {name: 'maio', code: 5, abrev: 'MAI'},
                    {name: 'junho', code: 6, abrev: 'JUN'},
                    {name: 'julho', code: 7, abrev: 'JUL'},
                    {name: 'agosto', code: 8, abrev: 'AGO'},
                    {name: 'setembro', code: 9, abrev: 'SET'},
                    {name: 'outubro', code: 10, abrev: 'OUT'},
                    {name: 'novembro', code: 11, abrev: 'NOV'},
                    {name: 'dezembro', code: 12, abrev: 'DEZ'},
                ],
                historicoChuvas: [],
                lineData: null,
                lineOptions: null,
                temperaturaMaxima: 0,
                temperaturaMininma: 0,
                temperaturaMedia: 0,
                precipitacaoMaxima: 0,
                precipitacaoMinima: 0,
                precipitacaoMedia: 0,
            }
        },

		created() {
            this.alterarParaPadraoHistoricoChuva();
            this.limparFiltros();
			this.listarEstacoesMeteorologicas();
            this.carregarAnos();
		},

        methods: {
            carregarAnos() {
                let anoFinal = new Date().getFullYear();
                for (let i = 2022; i <= anoFinal; i++) {
                    this.anos.push(i);
                }
            },
            listarEstacoesMeteorologicas() {
                EstacaoMeteorologicaService.listar()
                        .then( ({data}) => {
                            if (data != null) {
                                this.estacoes = data;
                            }
                        })
                        .catch(error => this.abrirToast('error', 'Falha', error.response.data.message_error, 3000));
            },
            buscarHistoricoChuva() {
                this.alterarParaPadraoHistoricoChuva();
                if (!this.validarCampos()) return;
                HistoricoChuvaService.buscarHistoricoChuvas(this.estacao.stationCode, this.ano, this.mes.code)
                        .then( ({data}) => {
                            this.historicoChuvas = data;
                            this.refreshChart(this.historicoChuvas);
                        })
                        .catch(error => this.abrirToast('error', 'Falha', error.response.data.message_error, 3000));
            },
            validarCampos() {
                let isValid = true;

                if (!this.estacao) {
                    isValid = false;
                }

                if (!this.ano) {
                    isValid = false;
                }

                if (!isValid) {
                    this.abrirToast('error', 'Falha', 'Escolher estação meteorológica e ano', 3000);
                    return;
                }

                return isValid;
            },
            limparFiltros() {
                this.estacao = {};
                this.ano = '';
                this.mes = this.meses[0];
                this.historicoChuvas = [];
            },
            alterarParaPadraoHistoricoChuva() {
                this.temperaturaMaxima = 0;
                this.temperaturaMininma = 0;
                this.temperaturaMedia = 0;
                this.precipitacaoMaxima = 0;
                this.precipitacaoMinima = 0;
                this.precipitacaoMedia = 0;
            },
            abrirToast(tipo, titulo, descricao, tempo) {
                this.$toast.add({
                    severity: tipo, 
                    summary: titulo, 
                    detail: descricao, 
                    life: tempo
                });
            },
            refreshChart(historicoChuvas) {
                this.lineData = this.getLineData(historicoChuvas);
                this.lineOptions = this.getLineChartOptions();
            },
            getLineData(historicoChuvas) {
                const isLight = this.$appState.layoutMode === 'light';
                const dataset1Color = isLight ? '#3854FF' : '#45C2FF';
                const dataset2Color = isLight ? '#21D94C' : '#32F825';
                const dataset3Color = isLight ? '#F57D33' : '#FFB74D';
                const dataLabels = [];
                const dataPrecipitacao = [];
                const dataTemperaturaMaxima = [];
                const dataTemperaturaMinima = [];
                let cont = 0;

                historicoChuvas.forEach(hc => {
                    if (this.mes.code == null) {
                        let mesLabel = this.meses.find(mes => {
                            if (mes.code == hc.lastUpdate.split('-')[1]) {
                                return mes;
                            }
                        });
                        dataLabels.push(mesLabel.abrev);
                    } else {
                        dataLabels.push(hc.lastUpdate.split('-')[2]);
                    }
                    
                    dataPrecipitacao.push(hc.precipitation);
                    dataTemperaturaMaxima.push(hc.temperatureMax);
                    dataTemperaturaMinima.push(hc.temperatureMin);
                    
                    cont++;
                    if (cont == 1) {
                        this.precipitacaoMinima = hc.precipitation;
                        this.temperaturaMininma = hc.temperatureMin;
                    }
                    
                    if (hc.precipitation > this.precipitacaoMaxima) {
                        this.precipitacaoMaxima = hc.precipitation;
                    }
                    
                    if (hc.precipitation < this.precipitacaoMinima) {
                        this.precipitacaoMinima = hc.precipitation;
                    }

                    this.precipitacaoMedia += hc.precipitation;

                    if (hc.temperatureMax > this.temperaturaMaxima) {
                        this.temperaturaMaxima = hc.temperatureMax;
                    }

                    if (hc.temperatureMin < this.temperaturaMininma) {
                        this.temperaturaMininma = hc.temperatureMin;
                    }

                    this.temperaturaMedia += hc.temperature;
                });

                this.precipitacaoMedia = this.precipitacaoMedia / historicoChuvas.length;
                this.temperaturaMedia = this.temperaturaMedia / historicoChuvas.length;

                return {
                    labels: dataLabels,
                    datasets: [
                        {
                            label: 'Milímetros de Chuva',
                            data: dataPrecipitacao,
                            fill: false,
                            backgroundColor: dataset1Color,
                            borderColor: dataset1Color,
                            tension: .4,
                        },
                        {
                            label: 'Temperatura Mínima',
                            data: dataTemperaturaMinima,
                            fill: false,
                            backgroundColor: dataset2Color,
                            borderColor: dataset2Color,
                            tension: .4,
                        },
                        {
                            label: 'Temperatura Máxima',
                            data: dataTemperaturaMaxima,
                            fill: false,
                            backgroundColor: dataset3Color,
                            borderColor: dataset3Color,
                            tension: .4,
                        }
                    ]
                };
            },

            getLineChartOptions() {
                const textColor = getComputedStyle(document.body).getPropertyValue('--text-color') || 'rgba(0, 0, 0, 0.87)';
                const gridLinesColor = getComputedStyle(document.body).getPropertyValue('--divider-color') || 'rgba(160, 167, 181, .3)';
                const fontFamily = getComputedStyle(document.body).getPropertyValue('--font-family');

                return {
                    plugins: {
                        legend: {
                            labels: {
                                font: {
                                    family: fontFamily
                                },
                                color: textColor,
                            },
                            position: 'bottom',
                        }
                    },
                    scales: {
                        x: {
                            ticks: {
                                font: {
                                    family: fontFamily
                                },
                                color: textColor
                            },
                            grid: {
                                color: gridLinesColor
                            }
                        },
                        y: {
                            ticks: {
                                font: {
                                    family: fontFamily
                                },
                                color: textColor
                            },
                            grid: {
                                color: gridLinesColor
                            }
                        }
                    }
                }
            },
            formatarDecimal(valor) {
                return Formatacao.formatDecimal(valor, 2, 3);
            },
        },
    }
</script>