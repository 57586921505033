import axios from "axios";
import PortalCooperadoUtils from '../utilities/PortalCooperadoUtils';

const API_BASE_URL = PortalCooperadoUtils.apiCooperadoService;
const RESOURCE_V1 = `/v1/historico-chuvas`;

class HistoricoChuvaService {
    buscarHistoricoChuvas(stationCode, ano, mes) {
        let params = {
            ano: ano,
            mes: mes
        }
        return axios.get(`${API_BASE_URL}${RESOURCE_V1}/${stationCode}`, {params});
    }
}

export default new HistoricoChuvaService();
