<template>
    <HistoricoChuva />
</template>

<script lang="js">
    import HistoricoChuva from '../../components/consulta/HistoricoChuva.vue'

    export  default {
        components: { HistoricoChuva }
    }
</script>