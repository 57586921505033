import axios from "axios";
import PortalCooperadoUtils from '../utilities/PortalCooperadoUtils';

const API_BASE_URL = PortalCooperadoUtils.apiCooperadoService;
const RESOURCE_V1 = `/v1/estacoes`;

class EstacaoMeteorologicaService {
    listar() {
        return axios.get(`${API_BASE_URL}${RESOURCE_V1}`);
    }
}

export default new EstacaoMeteorologicaService();
